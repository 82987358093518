import { calculateGridItemChildWidth } from '@rsa-digital/evo-shared-components/commonStyles/innerGridItemColumn';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import { fonts } from '@rsa-digital/evo-shared-components/theme/JohnLewis/fonts';
import styled from 'styled-components';
import CoverStartDate from 'components/CoverStartDate';
import RichTextWithModal from 'components/RichTextWithModal';
import { InfoPanel } from 'components/StatusPanel';
import { JointPolicyholderAdditionalFormSectionBanner } from 'forms/AboutYouForm/styles';

export const StyledInfoPanel = styled(InfoPanel)`
  margin: 0;
  li {
    ${fonts.paragraph}
  }
  p {
    ${fonts.paragraphLarge}
  }
  && {
    padding: ${spacing(3)};
  }
`;

export const StyledParagraphRichTextWithModal = styled(RichTextWithModal)`
  & p.large {
    ${fonts.paragraph}
  }
`;

export const CoPaymentTextContainer = styled.div`
  margin-top: ${spacing(1)};
  flex-basis: 100%;
  ${mediaQuery.tabletPortrait`
    width: ${calculateGridItemChildWidth(8, 4, 'tabletPortrait')};  
  `};

  ${mediaQuery.tabletLandscape`
    width: ${calculateGridItemChildWidth(6, 3, 'tabletLandscape')};  
  `};

  ${mediaQuery.desktop`
    width: ${calculateGridItemChildWidth(8, 4, 'desktop')};  
  `};
`;

export const ChildAfterContainer = styled.div`
  width: 100%;
  margin-top: ${spacing(2)};

  ${mediaQuery.tabletLandscape`
    margin-top: ${spacing(3)};
  `}
`;

export const ChildBeforeContainer = styled.div`
  width: 100%;
  margin-bottom: ${spacing(2)};

  ${mediaQuery.tabletLandscape`
    margin-bottom: ${spacing(3)};
  `}
`;

export const StyledRichTextWithModal = styled(RichTextWithModal)`
  ul {
    margin-top: 0;

    li {
      margin-top: 0;
    }
  }
`;

export const StyledRichTextWithModalWidth = styled(RichTextWithModal)`
  ${mediaQuery.tabletLandscape`
    width: 50%;
  `}
`;

export const StyledJointPolicyholderBanner = styled(
  JointPolicyholderAdditionalFormSectionBanner
)`
  margin-bottom: 0;
  padding-top: 0;
`;

export const StyledCoverStartDate = styled(CoverStartDate)`
  margin-bottom: -${spacing(4)};
`;
