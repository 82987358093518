import { Grid, GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import { FieldFunction } from '@rsa-digital/evo-shared-components/helpers/forms/types';
import { graphql, navigate, useStaticQuery } from 'gatsby';
import React, { Fragment, useEffect, useState } from 'react';
import CoverStartDate from 'components/CoverStartDate';
import useAboutYourPolicyQuestions from 'forms/AboutYourPolicyForm/questions';
import CheckContactDetailsForm from 'forms/CheckContactDetailsForm';
import JointPolicyholderForm from 'forms/JointPolicyholderForm';
import { PageTitle, trackTextButtonClick } from 'helpers/eventTracking';
import {
  combinePetNames,
  petNameReplacer,
} from 'helpers/placeholders/dynamicPetNameHelpers';
import { quoteAndBuyRoutes } from 'helpers/routingHelper';
import { CurrentQuote, useCurrentQuote } from 'helpers/useCurrentQuote';
import { ContactDetails } from 'state/formData/customerDetails';
import { useJointPolicyholderDetails } from 'state/formData/jointPolicyholderDetails';
import { usePetsDetails } from 'state/formData/petsDetails';
import DetailGroupSection from './DetailGroupSection';
import {
  CoverStartDateDetailGroupSectionContainer,
  CoverStartDateHeading,
  DetailGroupSectionContainer,
  StyledBody,
  StyledHeading,
} from './DetailGroupSection/styles';
import { StyledJointPolicyholderBanner } from './styles';
import useAboutYouSection from './useAboutYouSection';
import useAdditionalInformationSection from './useAdditionalInformationSection';
import useCoverLevelSection from './useCoverLevelSection';
import useExcessSections from './useExcessSections';
import usePetSections from './usePetSections';

type DetailsSectionProps = {
  currentQuote: CurrentQuote;
  getError: FieldFunction<ContactDetails, string | undefined>;
  showValidation: FieldFunction<ContactDetails, void>;
};

type DetailsSectionCsProps = {
  csPetCheckYourDetailsDetailsSectionV2: {
    about_you: {
      about_you_heading: string;
      edit_button_text: string;
      edit_button_screenreader_text: string;
    };
    about_your_joint_policyholder: {
      about_your_joint_policyholder_heading: string;
      edit_button_text: string;
      edit_button_screenreader_text: string;
    };
    about_your_pet: {
      about_your_pet_heading: string;
      edit_button_text: string;
      edit_button_screenreader_text: string;
    };
    excess: {
      excess_heading: string;
      edit_button_text: string;
      edit_button_screenreader_text: string;
    };
    your_cover_dates: {
      your_cover_dates_heading: string;
      edit_button_text: string;
      edit_button_screenreader_text: string;
    };
    your_cover_level: {
      your_cover_level_heading: string;
      edit_button_text: string;
      edit_button_screenreader_text: string;
    };
    additional_information: {
      additional_information_heading: string;
      edit_button_text: string;
      edit_button_screenreader_text: string;
    };
  };
  csPetAboutYou: {
    joint_policyholder_banner: {
      body_text: string;
      button_text: string;
      button_aria_label: string;
      icon: {
        icon_code: string;
      };
    };
  };
};

export const query = graphql`
  query {
    csPetCheckYourDetailsDetailsSectionV2 {
      about_you {
        about_you_heading
        edit_button_text
        edit_button_screenreader_text
      }
      about_your_joint_policyholder {
        about_your_joint_policyholder_heading
        edit_button_text
        edit_button_screenreader_text
      }
      about_your_pet {
        about_your_pet_heading
        edit_button_text
        edit_button_screenreader_text
      }
      excess {
        excess_heading
        edit_button_text
        edit_button_screenreader_text
      }
      your_cover_dates {
        your_cover_dates_heading
        edit_button_text
        edit_button_screenreader_text
      }
      your_cover_level {
        your_cover_level_heading
        edit_button_text
        edit_button_screenreader_text
      }
      additional_information {
        additional_information_heading
        edit_button_text
        edit_button_screenreader_text
      }
    }
    csPetAboutYou {
      joint_policyholder_banner {
        body_text
        button_text
        button_aria_label
      }
    }
  }
`;

const DetailsSection: React.FC<DetailsSectionProps> = ({
  currentQuote,
  getError,
  showValidation,
}) => {
  const {
    csPetCheckYourDetailsDetailsSectionV2: {
      about_you,
      about_your_joint_policyholder,
      about_your_pet,
      excess,
      your_cover_dates,
      your_cover_level,
      additional_information,
    },
    csPetAboutYou: {
      joint_policyholder_banner: {
        body_text: jointPolicyholderBannerBodyText,
        button_text: jointPolicyholderBannerButtonText,
        button_aria_label: jointPolicyholderBannerButtonAriaLabel,
      },
    },
  } = useStaticQuery<DetailsSectionCsProps>(query);

  const yourCoverDatesSectionId = 'your-cover-dates-section';
  const yourCoverLevelSectionId = 'your-cover-level-section';
  const aboutYouSectionId = 'about-you-section';
  const yourContactDetailsSectionId = 'your-contact-details-section';
  const aboutYourJointPolicyholderSectionId = 'about-your-joint-policyholder-section';
  const aboutYourPetSectionId = 'about-your-pet-section';
  const excessSectionId = 'excess-section';
  const additionalInformationSectionId = 'additional-information-section';

  const { coverStartDate } = useAboutYourPolicyQuestions();
  const [petsDetails] = usePetsDetails();
  const [
    jointPolicyholderDetails,
    updateJointPolicyholderDetails,
  ] = useJointPolicyholderDetails();
  const petsDetailsSections = usePetSections();
  const petsExcess = useExcessSections();
  const quote = useCurrentQuote();

  const [showJointPolicyholderForm, setShowJointPolicyholderForm] = useState<boolean>(
    jointPolicyholderDetails.includeJointPolicyholder
  );

  useEffect(() => {
    if (jointPolicyholderDetails.includeJointPolicyholder) {
      setShowJointPolicyholderForm(true);
    }
  }, [jointPolicyholderDetails]);

  const petNames = petsDetails.map((pet) => pet.petName);
  const combinedPetNames = combinePetNames(petNames ?? []);
  const coverStartDateExplanatoryText = petNameReplacer(
    combinedPetNames,
    coverStartDate.explanatoryText ?? ''
  );

  return (
    <>
      <CoverStartDateDetailGroupSectionContainer
        aria-labelledby={yourCoverDatesSectionId}
        id={yourCoverDatesSectionId}>
        <CoverStartDateHeading>
          {your_cover_dates.your_cover_dates_heading}
        </CoverStartDateHeading>
        <Grid alignLeft>
          <GridItem desktop={6} tabletLandscape={6}>
            <CoverStartDate
              pageTitle={PageTitle.CheckYourDetails}
              explanatoryText={coverStartDateExplanatoryText}
            />
          </GridItem>
        </Grid>
      </CoverStartDateDetailGroupSectionContainer>
      <DetailGroupSection
        sectionHeading={your_cover_level.your_cover_level_heading}
        sectionId={yourCoverLevelSectionId}
        details={useCoverLevelSection(quote)}
        editButtonOnClick={() => {
          trackTextButtonClick(PageTitle.CheckYourDetails, 'Edit cover level');
          navigate(quoteAndBuyRoutes.quoteSummary);
        }}
        editButtonLabel={your_cover_level.edit_button_text}
        editButtonScreenreaderText={your_cover_level.edit_button_screenreader_text}
      />
      {currentQuote?.petInfos?.map((petInfo, index) => (
        // Index is the unique identifier for pet info
        // eslint-disable-next-line react/no-array-index-key
        <Fragment key={index}>
          <DetailGroupSection
            sectionHeading={petNameReplacer(petInfo.petName, excess.excess_heading)}
            sectionId={`${excessSectionId}[${index}]`}
            details={petsExcess[index]}
            editButtonOnClick={() => {
              trackTextButtonClick(PageTitle.CheckYourDetails, 'Edit pet excess');
              navigate(`${quoteAndBuyRoutes.quoteSummary}#excess-section`);
            }}
            editButtonLabel={excess.edit_button_text}
            editButtonScreenreaderText={excess.edit_button_screenreader_text}
            piiOptions={{ headingIsPii: false, detailLabelsArePii: false }}
          />
        </Fragment>
      ))}
      <DetailGroupSection
        sectionHeading={additional_information.additional_information_heading}
        sectionId={additionalInformationSectionId}
        details={useAdditionalInformationSection()}
        editButtonOnClick={() => {
          trackTextButtonClick(PageTitle.CheckYourDetails, 'Edit additional information');
          navigate(`${quoteAndBuyRoutes.aboutYou}#numberOfPetsInHousehold`);
        }}
        editButtonLabel={additional_information.edit_button_text}
        editButtonScreenreaderText={additional_information.edit_button_screenreader_text}
        piiOptions={{ detailValuesArePii: false }}
      />
      {currentQuote?.petInfos?.map((petInfo, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Fragment key={index}>
          <DetailGroupSection
            sectionHeading={petNameReplacer(
              petInfo.petName,
              about_your_pet.about_your_pet_heading
            )}
            sectionId={`${aboutYourPetSectionId}[${index}]`}
            details={petsDetailsSections[index]}
            editButtonOnClick={() => {
              trackTextButtonClick(PageTitle.CheckYourDetails, 'edit pet details');
              navigate(`${quoteAndBuyRoutes.aboutYourPet}#petDetails[${index}]`);
            }}
            editButtonLabel={about_your_pet.edit_button_text}
            editButtonScreenreaderText={about_your_pet.edit_button_screenreader_text}
            piiOptions={{ headingIsPii: false, detailLabelsArePii: false }}
          />
        </Fragment>
      ))}
      <DetailGroupSection
        sectionHeading={about_you.about_you_heading}
        sectionId={aboutYouSectionId}
        details={useAboutYouSection()}
        editButtonOnClick={() => {
          trackTextButtonClick(PageTitle.CheckYourDetails, 'Edit about you');
          navigate(quoteAndBuyRoutes.aboutYou);
        }}
        editButtonLabel={about_you.edit_button_text}
        editButtonScreenreaderText={about_you.edit_button_screenreader_text}
        piiOptions={{ detailValuesArePii: true }}
      />
      <CheckContactDetailsForm
        getError={getError}
        showValidation={showValidation}
        sectionId={yourContactDetailsSectionId}
      />
      <DetailGroupSectionContainer
        id={aboutYourJointPolicyholderSectionId}
        aria-labelledby={aboutYourJointPolicyholderSectionId}>
        <StyledHeading>
          {about_your_joint_policyholder.about_your_joint_policyholder_heading}
        </StyledHeading>
        {showJointPolicyholderForm ? (
          <div>
            <StyledBody
              html={jointPolicyholderBannerBodyText}
              pageTitle={PageTitle.CheckYourDetails}
            />
            <JointPolicyholderForm
              removeJointPolicyholderButtonOnClick={() =>
                setShowJointPolicyholderForm(false)
              }
              getError={getError}
              showValidation={showValidation}
            />
          </div>
        ) : (
          <StyledJointPolicyholderBanner
            data-cy="addJointPolicyholderBanner"
            bodyText={jointPolicyholderBannerBodyText}
            buttonText={jointPolicyholderBannerButtonText}
            buttonAriaLabel={jointPolicyholderBannerButtonAriaLabel}
            pageTitle={PageTitle.AboutYou}
            buttonId="addJointPolicyholderButton"
            addFormSectionButtonClick={() => {
              updateJointPolicyholderDetails({ includeJointPolicyholder: true });
              setShowJointPolicyholderForm(true);
              trackTextButtonClick(PageTitle.AboutYou, 'Add joint policy holder');
            }}
          />
        )}
      </DetailGroupSectionContainer>
    </>
  );
};

export default DetailsSection;
