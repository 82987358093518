import { Grid, GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import LoadingOverlayV2 from '@rsa-digital/evo-shared-components/components/LoadingOverlayV2';
import { dateValueToISODateString } from '@rsa-digital/evo-shared-components/helpers/dateHelpers';
import useValidation from '@rsa-digital/evo-shared-components/helpers/forms/useValidation';
import { isQuoteOptionSelectionValid } from 'apiHelpers/quote/bundleCoverMapping';
import useGenerateQuote from 'apiHelpers/quote/useGenerateQuote';
import { isAggsQuote } from 'businessLogic/aggregators';
import { graphql, navigate } from 'gatsby';
import React, { useState } from 'react';
import LoadQuoteWrapper from 'components/ApiRequestWrapper/LoadQuoteWrapper';
import AggregatorAssumptionsSection from 'components/CheckYourDetails/AggregatorAssumptionsSection';
import useAssumptions from 'components/CheckYourDetails/AggregatorAssumptionsSection/assumptions';
import useAggregatorAssumptionsRules from 'components/CheckYourDetails/AggregatorAssumptionsSection/validation';
import DeclarationSection from 'components/CheckYourDetails/DeclarationSection';
import DetailsSection from 'components/CheckYourDetails/DetailsSection';
import useQuoteReference from 'components/CheckYourDetails/DetailsSection/useQuoteReference';
import { useCheckYourDetailsPdf } from 'components/CheckYourDetailsPdf/useCheckYourDetailsPdf';
import FormFooter from 'components/FormFooter';
import Layout from 'components/Layout';
import PricingOverviewPanel from 'components/PricingOverviewPanel';
import { QuoteAndBuyStep } from 'components/ProgressBar';
import { SessionExpiryOption } from 'components/SessionExpiry/SessionExpiryWrapper';
import { useContactDetailsRules } from 'forms/CheckContactDetailsForm/validation';
import { ProductId } from 'helpers/businessConstants';
import { useCheckoutTracking } from 'helpers/ecommerceTracking';
import {
  PageTitle,
  trackFieldError,
  trackSwitchPaymentClick,
  trackTextButtonClick,
} from 'helpers/eventTracking';
import { scrollAndTrackError } from 'helpers/forms';
import { usePageTracking } from 'helpers/pageTracking';
import { isQuoteSummaryDetailsInvalid } from 'helpers/quoteAndBuyPageFlowHelpers';
import { quoteAndBuyRoutes } from 'helpers/routingHelper';
import { useSaveAndEmailQuote } from 'helpers/saveQuoteHelpers';
import {
  PAYMENT_MODE_PREVIOUSLY_SELECTED_KEY,
  storeData,
} from 'helpers/sessionStorageHelpers';
import useAggregatorBrandDetails from 'helpers/useAggregatorBrandDetails';
import { useCurrentQuote, useUpdateQuoteOptions } from 'helpers/useCurrentQuote';
import useDeclarations from 'helpers/useDeclarations';
import { useAssumptionsAgreement } from 'state/formData/assumptionsAgreement';
import { useCustomerDetails } from 'state/formData/customerDetails';
import { useJointPolicyholderDetails } from 'state/formData/jointPolicyholderDetails';
import { usePolicyDetails } from 'state/formData/policyDetails';
import { initialQuoteOptions } from 'state/formData/quoteOptions';
import { CsHero } from 'types/contentStack';
import {
  ImageWithFixedHeight,
  ImageWrapper,
  QuoteReferenceLabel,
  QuoteReferenceValue,
  QuoteReferenceWrapper,
} from './styles';

const PAGE_LANDING_STEP = 8;

type CheckYourDetailsProps = {
  data: {
    csPetCheckYourDetails: {
      meta_title: string;
      hero: CsHero;
      next_button_text: string;
    };
    csPetGlobalConfig: {
      loading_spinner: {
        email_spinner_text: string;
      };
    };
  };
};

export const query = graphql`
  query {
    csPetCheckYourDetails {
      meta_title
      hero {
        heading
        subheading
      }
      next_button_text
    }
    csPetGlobalConfig {
      loading_spinner {
        email_spinner_text
      }
    }
  }
`;

const CheckYourDetails: React.FC<CheckYourDetailsProps> = ({
  data: {
    csPetCheckYourDetails: { hero, next_button_text, meta_title },
    csPetGlobalConfig: {
      loading_spinner: { email_spinner_text },
    },
  },
}) => {
  const aggregatorAssumptionsSectionId = 'aggregator-assumptions-section';
  const declarationSectionId = 'declaration-section';

  const quote = useCurrentQuote();
  const updateQuoteOptions = useUpdateQuoteOptions();
  const quoteOptions = quote?.quoteOptions ?? initialQuoteOptions;
  const { isAnnualPayment } = quoteOptions;

  const [customerDetails] = useCustomerDetails();
  const [policyDetails] = usePolicyDetails();
  const [jointPolicyHolderDetails] = useJointPolicyholderDetails();

  if (quote.petInfos) {
    if (isQuoteSummaryDetailsInvalid(quote.quoteOptions)) {
      navigate(quoteAndBuyRoutes.quoteSummary);
    }
  }

  const paymentOnClick = (): void => {
    trackSwitchPaymentClick(
      PageTitle.QuoteSummary,
      isAnnualPayment ? 'monthly' : 'annually'
    );
    updateQuoteOptions({
      isAnnualPayment: !isAnnualPayment,
    });
  };

  const contactDetailsRules = useContactDetailsRules();

  const {
    declarations,
    updateDeclarationAgreement,
    declarationRules,
  } = useDeclarations();

  const aggregatorBrandDetails = useAggregatorBrandDetails(quote.productId);

  const aggregatorAssumptionRules = useAggregatorAssumptionsRules();

  const [assumptionsAgreement] = useAssumptionsAgreement();

  const rules = {
    ...contactDetailsRules,
    ...declarationRules,
    ...(isAggsQuote(quote) ? aggregatorAssumptionRules : {}),
  };

  const { getError, validateOnSubmit, showValidation } = useValidation(
    {
      ...customerDetails,
      declarations,
      ...assumptionsAgreement,
    },
    rules,
    trackFieldError
  );

  const assumptions = useAssumptions();

  /*  We only display the aggregator assumptions section if some
   *  assumptions exist and the assumptions have not yet been agreed
   *  to when the page is first rendered, so we must record the assumptionsAgreed value on render.
   */
  const [assumptionsAgreedOnRender] = useState(assumptionsAgreement.assumptionsAgreed);

  const {
    saveAndEmailQuote,
    savedQuoteConfirmationModal,
    isSaveInProgress,
  } = useSaveAndEmailQuote(PageTitle.CheckYourDetails);

  const { createQuote: requote, isLoading: isRequoting } = useGenerateQuote();

  const isPolicyModified =
    customerDetails.customerEmail !== quote.customerInfo?.email ||
    customerDetails.customerTelephone !== quote.customerInfo?.contactPhoneNumber ||
    policyDetails.coverStartDate !== quote.policyInfo?.coverStartDate ||
    jointPolicyHolderDetails.includeJointPolicyholder !==
      quote.policyInfo?.includeJoinPolicyHolder ||
    (jointPolicyHolderDetails.includeJointPolicyholder &&
      jointPolicyHolderDetails.jointPolicyholderTitle !==
        quote.policyInfo?.joinPolicyHolder?.title) ||
    (jointPolicyHolderDetails.includeJointPolicyholder &&
      jointPolicyHolderDetails.jointPolicyholderFirstName !==
        quote.policyInfo?.joinPolicyHolder?.firstName) ||
    (jointPolicyHolderDetails.includeJointPolicyholder &&
      jointPolicyHolderDetails.jointPolicyholderLastName !==
        quote.policyInfo?.joinPolicyHolder?.lastName) ||
    (jointPolicyHolderDetails.includeJointPolicyholder &&
      dateValueToISODateString(jointPolicyHolderDetails.jointPolicyholderDob) !==
        quote.policyInfo?.joinPolicyHolder?.dob);

  const moveNext = async (): Promise<void> => {
    if (isPolicyModified) {
      await requote();
    }
    navigate(quoteAndBuyRoutes.payment, {
      state: { detailsValid: true },
    });
  };

  const showAssumptions = !!assumptions && !assumptionsAgreedOnRender;
  const generatePdf = useCheckYourDetailsPdf(
    quote,
    declarations,
    assumptionsAgreement,
    showAssumptions
  );

  usePageTracking(meta_title, !!quote.customerInfo);
  useCheckoutTracking(PAGE_LANDING_STEP, quote, true);

  const pageGridProps = {
    desktop: 8,
    tabletLandscape: 8,
    tabletPortrait: 6,
  };

  const quoteReference = useQuoteReference();

  return (
    <LoadQuoteWrapper>
      <Layout
        currentStep={QuoteAndBuyStep.CheckDetails}
        pageTitle={PageTitle.CheckYourDetails}
        heading={hero.heading}
        subheading={hero.subheading}
        metaTitle={meta_title}
        sessionExpiryOption={
          isQuoteOptionSelectionValid(quoteOptions)
            ? SessionExpiryOption.EMAIL_QUOTE_SESSION_EXPIRY
            : undefined
        }>
        <Grid>
          <GridItem {...pageGridProps}>
            {isRequoting && (
              <LoadingOverlayV2
                loadingMessage="Updating contact details ..."
                timeDuration={10}
              />
            )}
            {isSaveInProgress && (
              <LoadingOverlayV2 loadingMessage={email_spinner_text} timeDuration={10} />
            )}
            <form onSubmit={validateOnSubmit(moveNext, scrollAndTrackError)}>
              {quote.productId !== ProductId.DIRECT && (
                <>
                  {aggregatorBrandDetails?.logo && (
                    <Grid data-cy="aggregator-logo">
                      <GridItem>
                        <ImageWrapper>
                          <ImageWithFixedHeight image={aggregatorBrandDetails.logo} />
                        </ImageWrapper>
                      </GridItem>
                    </Grid>
                  )}
                  {showAssumptions && (
                    <section aria-labelledby={aggregatorAssumptionsSectionId}>
                      <AggregatorAssumptionsSection
                        sectionHeadingId={aggregatorAssumptionsSectionId}
                        getError={getError}
                        showValidation={showValidation}
                      />
                    </section>
                  )}
                </>
              )}
              <QuoteReferenceWrapper>
                <QuoteReferenceLabel>{quoteReference.label} </QuoteReferenceLabel>
                <QuoteReferenceValue>{quoteReference.value}</QuoteReferenceValue>
              </QuoteReferenceWrapper>
              <DetailsSection
                currentQuote={quote}
                getError={getError}
                showValidation={showValidation}
              />
              <PricingOverviewPanel
                pageTitle={PageTitle.CheckYourDetails}
                paymentOnClick={paymentOnClick}
              />
              <section aria-labelledby={declarationSectionId}>
                <DeclarationSection
                  sectionHeadingId={declarationSectionId}
                  declarations={declarations}
                  updateHasAgreed={(update: boolean, index: number) => {
                    updateDeclarationAgreement(update, index);
                    showValidation('declarations', ['hasAgreed', index]);
                  }}
                  getError={getError}
                />
              </section>
              <FormFooter
                contentColumns={{ desktop: 8 }}
                backButton={{
                  onClick: () => {
                    trackTextButtonClick(PageTitle.CheckYourDetails, 'Back');
                    navigate(quoteAndBuyRoutes.quoteSummary);
                  },
                }}
                moveNextButton={{
                  text: next_button_text,
                  onClick: () => {
                    storeData(
                      PAYMENT_MODE_PREVIOUSLY_SELECTED_KEY,
                      quote.quoteOptions.isAnnualPayment ? 'ANNUAL' : 'MONTHLY'
                    );
                    trackTextButtonClick(PageTitle.CheckYourDetails, 'Continue');
                  },
                }}
                downloadButton={{
                  onClick: generatePdf,
                }}
                saveButton={{
                  onClick: () => {
                    if (isQuoteOptionSelectionValid(quoteOptions)) {
                      saveAndEmailQuote();
                    }
                  },
                }}
                pageTitle={PageTitle.CheckYourDetails}
              />
              {savedQuoteConfirmationModal}
            </form>
          </GridItem>
        </Grid>
      </Layout>
    </LoadQuoteWrapper>
  );
};

export default CheckYourDetails;
