import { graphql, useStaticQuery } from 'gatsby';
import { useTheme } from 'styled-components';
import { ProductFeature } from 'components/QuoteSummary/ProductFeatureTable';
import { quotePlaceholders } from 'helpers/placeholders/quotePlaceholders';
import {
  noOpPlaceholderReplacer,
  replacePlaceholdersPlainText,
} from 'helpers/placeholders/replaceCsPlaceholders';
import { useCurrentQuote } from 'helpers/useCurrentQuote';
import { ProductFeatureSectionProps } from './ProductFeatureSection';

type ProductSectionContent = {
  csPetQuoteSummaryProductFeatures: {
    product_features: ProductFeature[];
    this_cover_includes_text: string;
  };
};

const query = graphql`
  query {
    csPetQuoteSummaryProductFeatures {
      product_features {
        feature_text
      }
      this_cover_includes_text
    }
  }
`;

export const useProductFeatureSectionProps = (): ProductFeatureSectionProps => {
  const {
    csPetQuoteSummaryProductFeatures: { product_features, this_cover_includes_text },
  } = useStaticQuery<ProductSectionContent>(query);
  const features = product_features;
  const sectionHeading = this_cover_includes_text;
  const quote = useCurrentQuote();
  const substitutePlaceholders = quote
    ? replacePlaceholdersPlainText(quotePlaceholders, quote, true)
    : noOpPlaceholderReplacer;
  const theme = useTheme();

  return {
    sectionHeading,
    features,
    substitutePlaceholders,
    theme,
  };
};
